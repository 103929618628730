import './RecipeCard.css';

function RecipeCard({ onCardClick, background, background2, beanType, name_original, name_replacement, recipeTitle, description }) {
    return (
        <div>
            {/* Recipe card */}
            <div className={"card recipe-card bean-" + beanType} onClick={onCardClick}>
                <div className="card-face front">
                    <div className="card-image-wrapper">
                        <img className="card-image" src={background2} />
                    </div>
                    <div className="recipe-title">
                        <h2>{name_replacement}</h2>
                        <img className="arrow-right" src={`/arrow-right-white.svg`} />
                    </div>
                </div>
                <div className="card-face back">
                    <div className="card-image-wrapper">
                        <img className="card-image" src={background} />
                    </div>
                    <div className="recipe-title">
                        <h2>{name_original}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecipeCard;