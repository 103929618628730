import React, { useEffect, useRef } from 'react';
import RecipeCard from './RecipeCard.js';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import imagePrev from './images/swiper-prev.svg';
import imageNext from './images/swiper-next.svg';

function RecipeCards({ onCardClick, recipes }) { // Accept recipes as prop
    const swiperRef = useRef(null);

    useEffect(() => {
      if (swiperRef.current) {
        const cards = swiperRef.current.querySelectorAll('.card');

        // Convert NodeList to Array and reverse it to start from the rightmost card
        const cardsArray = Array.from(cards).reverse();

        // Loop through each card and add a delay based on its index
        cardsArray.forEach((card, index) => {
          setTimeout(() => {
            card.classList.add('flip');
          }, index * 100); // 0.1 second delay for each card
        });
      }
    }, [recipes]);

    const params = {
        slidesPerView: 'auto',
        rewind: true,
        spaceBetween: 20,
        pagination: {
            clickable: true
        }
    };

    return (
        <div class="recipe-cards">

            <h2>KIES OOK EEN KEER VOOR BONEN EN GA AAN DE SLAG MET DEZE GERECHTEN</h2>

        <Swiper {...params}
        modules={[Navigation]}
        navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        }}
        ref={swiperRef}>
            {recipes.map((recipe, index) => (
                <SwiperSlide key={index}>
                    <RecipeCard
                        modules={[Navigation]}
                        onCardClick={() => onCardClick(recipe)}
                        background={recipe.background}
                        background2={recipe.background2}
                        beanType={recipe.bean_type}
                        name_original={recipe.name_original}
                        name_replacement={recipe.name_replacement}
                        description={recipe.description}
                    />
                </SwiperSlide>
            ))}
            <div className="swiper-button-prev">
                <span className="button-label">Vorige</span>
                <img className="card-image" src={imagePrev} />
            </div>
            <div className="swiper-button-next">
                <span className="button-label">Volgende</span>
                <img className="card-image" src={imageNext} />
            </div>
        </Swiper>
        </div>

    );
}

export default RecipeCards;