import React from 'react';
import './RecipeDetailPopup.css';

function RecipeDetailPopup({ content, onClose }) {
    if (!content) return null;

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="popup popup-alternative" onClick={handleOutsideClick}>
            <div className="popup-content" onClick={e => e.stopPropagation()}>
                <p className="small-text">
                    <svg className="close-button" onClick={onClose} width="40px" height="40px" viewBox="0 0 40 40">
                        <title>Sluiten</title>
                        <path d="M39.74,19.87 C39.49,17.83 38.01,13.91 37.71,11.88 C37.39,9.67 34.39,6.6 32.99,4.8 C30.82,2.04 28.44,1.2 23.04,0.26 C20.5,0 17.84,0.1 15.41,0.74 C12.75,1.66 9.34,3.23 5.2,5.44 C1.74,7.85 0,14.2 0,24.52 L0,26.32 C4.92,35.43 10.04,40.33 15.31,39.98 C17.23,39.86 21.78,39.2 24.36,38.82 C25.6,38.64 26.84,38.32 27.99,37.84 C38.35,33.59 40.9,29.1 39.74,19.87" fill="#9CCB55" className="path"></path>
                        <path d="M21.86,27.84 C21.83,28.07 20.24,28.08 17.09,27.89 L17.04,27.97 C16.63,27.91 16.55,26.15 16.78,22.67 C15.47,22.84 14.52,22.92 13.93,22.92 L13.93,22.79 C13.93,22.54 13.77,22.54 13.45,22.79 L13.01,23 C11.61,23 10.91,21.64 10.91,18.91 C10.91,18.19 12.5,17.66 15.68,17.33 C15.68,17.24 15.89,17.19 16.3,17.16 L16.47,17.2 C16.41,15.37 16.43,14.07 16.52,13.32 L16.69,13.36 L16.74,12.9 L16.56,12.45 C16.74,11.14 18.24,10.66 21.07,11.03 C21.83,11.11 22.2,12.68 22.17,15.74 C22.23,15.74 22.24,15.94 22.21,16.33 L22.04,16.83 L28.04,15.99 L28.35,15.99 C28.58,15.99 28.8,17.51 29,20.54 L29.09,20.54 C29.09,20.96 27.02,21.29 22.87,21.54 C22.52,21.63 22.17,21.7 21.82,21.75 L21.91,27.55 L21.86,27.84 Z" stroke="#9CCB55" fill="#fff" transform="translate(20, 19.47) rotate(-45) translate(-20, -19.47)"></path>
                    </svg>
                    <span>{content}</span>
                </p>
            </div>
        </div>
    );
}

export default RecipeDetailPopup;